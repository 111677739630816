import * as React from "react"
import Seo from "../components/seo"

const AnswerPage = () => {
  return (
    <section id="answer">
      <Seo
        title="The Answer"
        description="Education is the key to better jobs and a stronger economy. We must keep our most vulnerable students engaged and in school. Education delivery matters."
      />
      <div className="one split-half-circle-right">
        <div className="container">
          <div className="content full-screen">
            <div className="left">
              <div className="wrap-content">
                <h2>The Greater Good</h2>
                <p>
                  Education is the key to better jobs for individuals and a
                  stronger economy for all.
                </p>
                <p>
                  The pandemic exposed the detriment of a factory-style,
                  one-size-fits-all education system – especially for students
                  of color and those living in poverty.
                </p>
                <p>
                  It is critical to keep our most vulnerable students engaged so
                  they do not fall back into the dropout cycle.
                </p>
              </div>
            </div>
            <div className="right">
              <div className="circle-pic"></div>
            </div>
          </div>
          <div className="content mobile">
            <div className="wrap-content">
              <h2>The Greater Good</h2>
              <p>
                Education is the key to better jobs for individuals and a
                stronger economy for all.
              </p>
              <p>
                The pandemic exposed the detriment of a factory-style,
                one-size-fits-all education system – especially for students of
                color and those living in poverty.
              </p>
              <p>
                It is critical to keep our most vulnerable students engaged so
                they do not fall back into the dropout cycle.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="two big-text-center">
        <div className="container">
          <div className="content">
            <p>
              Children of dropouts are more likely to drop out. Conversely,{" "}
              <br />
              every dropout who earns a diploma is:
            </p>
            <div className="stats">
              <p>
                <strong>6X</strong> more likely to vote
              </p>
              <p>
                <strong>26X</strong> less likely to be unemployed
              </p>
              <p>
                <strong>6X</strong> less likely to be incarcerated
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="three half-pic-left">
        <div className="full-screen">
          <div className="left"></div>
          <div className="right">
            <div className="content">
              <p>
                At least <strong>10-15%</strong> of students are not graduating.
                How do we reengage, support and guide them to becoming
                successful in school and life?
              </p>
            </div>
          </div>
        </div>
        <div className="mobile">
          <div className="overlay">
            <div className="content">
              <p>
                At least <strong>10-15%</strong> of students are not graduating.
                How do we reengage, support and guide them to becoming
                successful in school and life?
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="four big-text-center">
        <div className="container">
          <div className="content">
            <h3>Here’s our take on the answer:</h3>
            <ul>
              <li>
                1. Ensure equitable access to a quality education for all
                students
              </li>
              <li>
                2. Personalize education to be flexible to the individual
                student{" "}
              </li>
              <li>
                3. Prepare students for a future beyond the diploma with job and
                life skills
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AnswerPage
